import { Elm } from "./Main.elm";
import registerServiceWorker from "./registerServiceWorker";

Elm.Main.init({
  node: document.getElementById("root"),
  environment: process.env.NODE_ENV,
  apiUrl: process.env.ELM_APP_API_URL
});

registerServiceWorker();
